import React, { Component } from 'react';
import { Grid, Icon } from 'semantic-ui-react';

class WebsiteMessagePage extends Component
{

  render()
  {
    const { icon, title, content } = this.props;

    return <React.Fragment>
        <Grid centered={true}>
            <div className="marginTopHuge">
                <Icon name={ icon } size='massive'/>
                <h2>{title}</h2>
                <p className="marginTopMedium">
                    { content }
                </p>
            </div>
        </Grid> ;
    </React.Fragment>
  }
}

export default WebsiteMessagePage;