import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react';
import { Link } from "react-router-dom";
import axios from 'axios';
import StandardHelmet from './parts/standardHelmet';
import LatingateLogo from '../images/latingate_logo.png';
import LaCandelaLogo from '../images/la_candela_logo.png';
import MediaNocheLogo from '../images/media_noche_variation_collection_logo.png';

const productionsEndpoint = '/api/productions';
class Index extends Component
{
  state = {
    productions: []
  };

  componentDidMount()
  {          
      axios.get(productionsEndpoint).then( (res) =>
      {
          const productions = res.data;
          this.setState({ productions });
      } );
  };

  render()
  {
    return <React.Fragment>
      <StandardHelmet title = 'About | Salsa Yo' />
      <Grid centered={true}>
        <Grid.Column width = {16}>
          <div className="lightbox">
              <h1>About</h1>
              <p className="textJustify"> 
                Salsayo previously known as Salsa Lust is a project that started in 2010 by Ido Flaishon
                a Cuban Salsa enthusiastic from Israel. The project goal is creating an
                encyclopedia for Cuban Salsa that will make sense and order of the massive amount of creativity
                that exists in Cuban Salsa.
              </p>
              <p>
                Supported by a few enthusiastic and expereinced souls, the project has become a reality.
                We put extra effort not just upload everything that exists on web, since there
                are a lot of duplications, misunderstandings and the like. Our moves are selected carefully, 
                and we select the videos to be shown based on their quality of producion and technical perofrmance level.
              </p>
              <p>
                Ido himself is a Cuban Salsa professional dancers and teacher. He is the head of the 
                <a href="http://la-candela-salsa.de/" target="_blank" rel="noopener noreferrer"> La Candela </a> 
                school in Berlin where he currently teaches Cuban Salsa, Foklore, and modern dances.
              </p>
          </div>

          <div className="center">
            <h1>Credits</h1>
            <p className="textJustify">
                We have a gathered and organized a massive amount of information,and it's all thanks to
                wonderful people that support the salsa secene all over the world. Here we list the people
                that and organizations that have contributed more than a bit.
            </p>

            <h2>Website Contribution</h2>
            <Link to="/contact"><h3>Ido Flaishon</h3></Link>
            <p>Salsayo admin, Web site design, coding and content writing & Catalogization</p>
            <h3>Michael 'Che' Morozov</h3>
            <p>Description for massive amount of moves, General help and professional advice.</p>
            <h3>Ofer Waldman</h3>
            <p>Media Noche filming chief, General help & proffessional advices</p>
            <h3>Predrag Stakić</h3>
            <p>Logo Design & Branding</p>

            <h2>In Association With</h2>
            <a href="http://la-candela-salsa.de/" target="_blank" rel="noopener noreferrer">
                <img src={LaCandelaLogo} alt="La Candela Logo"/>
                <p>La Candela Berlin School</p>
            </a> 
            <a href="http://www.no-camel.com/media_noche/index.html#variations_collection_title" target="_blank" rel="noopener noreferrer">
                <img src={LatingateLogo} alt="Latingate Logo" />
                <p>Latingate latin dance school</p>
            </a> 
            <a href="http://www.latingate.co.il/" rel="noopener noreferrer">
                <img src={MediaNocheLogo} alt="Medianoche variation collection Logo" />
                <p>Media Noche - Variation Collection</p>
            </a> 

            <h2>Salsa Yo filming Productions</h2>
            <h3>Moves Creator</h3>
            <p>Or Felus</p>
            <h3>Dancers</h3>
            <p>Or Felus, Tamar Levy, Shirly Bitansky, Tal Gafni, Nofar Teper</p>
            <h3>Filming & Editing</h3>
            <p>Ido Flaishon</p>

            <h2>Moves Films</h2>
            <p>Filming groups ordered by contribution to Salsayo video archive</p>
            <p>
            {
              this.state.productions.map( (production, index) => {
                return <React.Fragment key={index}><a href={production.link} target="_blank" rel="noopener noreferrer">{production.name}</a> ~ </React.Fragment>;
              })
            }
            </p>
          </div>
        </Grid.Column>
      </Grid>
    </React.Fragment>
  }
}

export default Index;