import React, { Component } from 'react';
import { Grid, Icon, Loader } from 'semantic-ui-react';
import { Link } from "react-router-dom";
import { FORM_STATUS } from '../../common/enums'
import queryString from 'query-string';
import UserService from '../../services/userService';
import LoginModal from './loginModal';

class ConfirmEmail extends Component
{
    state = {
        status  : FORM_STATUS.INITIAL,
        errorMessage : ''
    }

    componentDidMount()
    {
        const { userId, token } = queryString.parse(this.props.location.search);

        this.setState( { status : FORM_STATUS.SENDING } );
        
        UserService.confirmEmail( userId, token)
                    .then( () => 
                    {
                        this.setState( { 
                            status : FORM_STATUS.SUCCESS,
                        } );
                    })
                    .catch( ( errors ) =>
                    {
                        this.setState( { 
                            status        : FORM_STATUS.ERROR,
                            errorMessage : errors[0].data
                        } );
                    });
    }

    render()
    {
        return <React.Fragment>
            <Grid centered={true}>
                { 
                    (this.state.status === FORM_STATUS.INITIAL || this.state.status === FORM_STATUS.SENDING) &&
                    <Loader size='massive' active={true} inline="centered" className="marginTopMedium">
                        Confirming...
                    </Loader>
                }
                { this.state.status === FORM_STATUS.SUCCESS &&
                    <div className="marginTopHuge">
                        <Icon name='check' size='massive'/>
                        <h2>Registration Complete - Your email is confirmed</h2>
                        <p className="marginTopMedium">
                            You may now <LoginModal/>
                        </p>
                    </div>
                } 
                { this.state.status === FORM_STATUS.ERROR &&
                    <div>
                        <Icon name='close' size='massive'/>
                        <h2>Confirmation failed</h2>
                        <p className="marginTopMedium">
                            Email confirmation failed: <br/>{this.state.errorMessage}
                            <br/><br/>
                            You may <Link className="colorStrongBlue" to="/contact">contact</Link> us and we will do our best to help you.
                        </p>
                    </div>
                }
            </Grid>
        </React.Fragment>
    }
}

export default ConfirmEmail;