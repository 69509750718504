import React, { Component }from 'react';
import { Dropdown, Menu } from 'semantic-ui-react';
import UserService from '../../services/userService';
import LoginModal from './loginModal';
import UserContext from '../../contexts/UserContext';
import { Link } from "react-router-dom";

class UserControl extends Component
{

    logout()
    {
        UserService.logout();
        window.location.reload();
    }

    LoggedUserSection( user )
    {
        const name = ( user ) ? user.firstName : '';

        return <p>Hey <Dropdown inline text={name}>
                <Dropdown.Menu className="mymenu">
                    <Dropdown.Item>
                        <Menu.Item as={ Link } to="/moves?favorite=1">My favorite moves</Menu.Item>
                        <Menu.Item onClick={this.logout}>Log Out</Menu.Item>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </p>;
    }

    render()
    {
        if ( UserService.isLoggedIn())
        {
            return <UserContext.Consumer>
            {
                ( { user } )  => {
                    return this.LoggedUserSection( user );
                }
            }
            </UserContext.Consumer>;
        }
        else
        {
            return <div>
                <LoginModal/>
                { '  |  ' }
                <Link to="/register">Register</Link>
            </div>
        }
    }
}

export default UserControl;