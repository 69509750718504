import lib from './fields.js';

export const Levels = [
    { text: 'Basics', value: 1},
    { text: 'Beginners', value: 2},
    { text: 'Improvers', value: 3},
    { text: 'Intermediate', value: 4},
    { text: 'Advanced', value: 5},
    { text: 'Expert', value: 6},
    { text: 'Master', value: 7},
]

export const GroupedBarsLength = [
    { text: 'Short', value: '1-2'},
    { text: 'Medium', value: '3-5'},
    { text: 'Long', value: '6-8'},
    { text: 'Very Long', value: '8-99'},
    { text: 'Se Fue / Ultima', value: '100'},
]

export const StartsWith = [
    { text: 'Enchufala', value: 'Enchufala'},
    { text: 'Lento', value: 'Lento'},
    { text: 'Setenta', value: 'Setenta'},
    { text: 'Siete', value: 'Siete'},
    { text: 'Sombrero', value: 'Sombrero'},
]

export const BasedOn = [
    { text: 'Enchufala', value: 'Enchufala'},
    { text: 'Setenta', value: 'Setenta'},
    { text: 'Sombrero', value: 'Sombrero'},
]

export const DanceConfiguration = [
    { text: 'Rueda Only', value: 2},
    { text: 'Couple Move', value: 1},
]

export const FavoriteOptions = [
    { text: 'Favorite', value: 1},
    { text: 'Not Favorite ', value: 0},
]

export default lib;