import React from 'react';
import './socialBar.css';
import { Grid } from 'semantic-ui-react';
import { FacebookProvider, Like } from 'react-facebook';
import {
    FacebookIcon,
    TwitterIcon,
    EmailIcon,
    RedditIcon,
    VKIcon,
    PinterestIcon,
    WhatsappIcon,

    FacebookShareButton,
    WhatsappShareButton,
    PinterestShareButton,
    VKShareButton,
    RedditShareButton,
    EmailShareButton,
    TwitterShareButton
  } from 'react-share';

  const LOGO_URL = `${window.location.origin}/images/logo.png`;
class SocialBar extends React.Component
{

  render()
  {
    const textAlign = this.props.textAlign || 'center';
    const shareUrl = window.location.href;
    const shareButtonProps = {
        url       : shareUrl,
        className : 'socialButton'
    }
    const iconProps = {
        size  : 32,
        round : true
    }

    return <Grid textAlign = { textAlign } className="socialbar" {...this.props}>
        <Grid.Row className="releaseSpacing">
            <FacebookShareButton {...shareButtonProps}>
                <FacebookIcon {...iconProps} />
            </FacebookShareButton>
            <TwitterShareButton {...shareButtonProps}>
                <TwitterIcon {...iconProps} />
            </TwitterShareButton>
            <PinterestShareButton {...shareButtonProps} media={LOGO_URL}>
                <PinterestIcon {...iconProps} />
            </PinterestShareButton>
            <RedditShareButton {...shareButtonProps}>
                <RedditIcon {...iconProps} />
            </RedditShareButton>
            <WhatsappShareButton {...shareButtonProps}>
                <WhatsappIcon {...iconProps} />
            </WhatsappShareButton>
            <EmailShareButton {...shareButtonProps}>
                <EmailIcon {...iconProps} />
            </EmailShareButton>
            <VKShareButton {...shareButtonProps}>
                <VKIcon {...iconProps} />
            </VKShareButton>
            <FacebookProvider appId="114182361936200">
                <Like href="https://www.facebook.com/salsayo.official/" showFaces={false} size='large' layout='button_count' />
            </FacebookProvider>
        </Grid.Row>
    </Grid>;
  }
}

export default SocialBar;