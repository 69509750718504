import React, { useState } from 'react';
import { Grid,Input,Label,Image,Divider } from 'semantic-ui-react';
import StandardHelmet from './parts/standardHelmet';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import classNames from 'classnames';

import DonationSmall from '../images/heart-small.svg';
import DonationBig from '../images/heart-big.svg';
import DonationQuestionMark from '../images/heart-question-mark.svg';

import CheckoutForm from './payment/checkoutForm';

const DONATIONS_AMOUNT = {
    small: 10,
    big: 25,
    custom : null
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_CLIENT_KEY);

function Support() 
{
    const [donationSize, setDonationSize] = useState('');
    const [donationAmount, setDonationAmount] = useState(null);

    const toggleImageClass = ( value ) =>
    {
        return classNames({
            toggleImage : true,
            activeImageToggle : donationSize === value
          });
    }

    const handleDonationSizeClick = ( value ) =>
    {

        setDonationSize( value );
        setDonationAmount( DONATIONS_AMOUNT[ value ] );
    }

    return <React.Fragment>
        <StandardHelmet title = 'Support | Salsa Yo'
                        description = 'Support the project.' />
        <Grid centered={true}>
            <Grid.Column width = {16}>
                <div className="lightbox">
                    <h1>Support the project</h1>
                    <p className="textJustify"> 
                        The website and the project comes with different costs and a lot of maintanence effort, not to mention Salsa related expertise.
                        The advertisement barely cover the costs or allow us to grow the project and I often end up
                        using my personal pocket for it. So if you have enjoyed the project so we welcome any contribution small as it may be.
                    </p>
                </div>

                <Image.Group size='small' style={{textAlign:'center'}}>lue='sm
                    <Image src={DonationSmall} 
                           label="Token of love" 
                           onClick={() => handleDonationSizeClick('small')}
                           className={toggleImageClass('small')}/>
                    <Image src={DonationBig}
                           label="Support us"
                           onClick={() => handleDonationSizeClick('big')}
                           className={toggleImageClass('big')}/>
                    <Image src={DonationQuestionMark}
                           label="As you wish"
                           onClick={() => handleDonationSizeClick('custom')}
                           className={toggleImageClass('custom')}/>
                </Image.Group>
                {
                (donationSize !== '') && <Grid centered={true}>
                    <Grid.Column computer={8} mobile={16} tablet={12}>
                        <br/>
                        {
                        (donationSize === 'custom') ?
                        <Input labelPosition='right'
                               type='number'
                               placeholder='Amount'
                               onChange={ e => setDonationAmount( e.target.value ) }  // TODO: The state is not working well
                               fluid>
                            <Label basic>€</Label>
                            <input />
                            <Label>.00</Label>
                        </Input> : 
                        <div className='bold textCenter' style={{ fontSize: '2em'}}>{`${DONATIONS_AMOUNT[donationSize]}€`}</div>
                        }
                        <Elements stripe={stripePromise}>
                            <CheckoutForm amount={donationAmount * 100}/>
                        </Elements>

                        <Divider horizontal section>Alternatively</Divider>

                        <div className='textCenter'>
                            <p>Donate via Paypal:</p>
                            <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                                <input type="hidden" name="cmd" value="_s-xclick" />
                                <input type="hidden" name="hosted_button_id" value="CBM2VMYG6LCS8" />
                                <input type="image" src="https://www.paypalobjects.com/en_US/DK/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                                <img alt="" border="0" src="https://www.paypal.com/en_DE/i/scr/pixel.gif" width="1" height="1" />
                            </form>
                        </div>
                    </Grid.Column>
                </Grid>
                }
            </Grid.Column>
        </Grid>
</React.Fragment>
};

export default Support;