import React, { Component } from 'react';
import { Grid, Icon } from 'semantic-ui-react';
import { Link } from "react-router-dom";
class NotFound extends Component
{

  render()
  {
    return <React.Fragment>
        <Grid centered={true}>
            <div className="marginTopHuge">
                <Icon name='ban' size='massive'/>
                <h2>Page Not Found</h2>
                <p className="marginTopMedium">
                    Follow to the <Link className="colorStrongBlue" to="/">home page</Link> and search for what you look for again. 
                    <br/>
                    You can also go straight to our <Link to="/moves">moves list</Link>.
                    <br/>
                    If the problem persist you are welcome to <Link to="/contact">contact us</Link>.
                </p>
            </div>
        </Grid> ;
    </React.Fragment>
  }
}

export default NotFound;