import React from 'react';
import Ad from './ad';
import { Responsive } from 'semantic-ui-react';

class ResponsiveLeaderboardAd extends React.Component
{
  render()
  {
    return <React.Fragment>
        <Responsive minWidth={Responsive.onlyComputer.minWidth}>
            <Ad unit='leaderboard' />
        </Responsive> 
        <Responsive {...Responsive.onlyTablet}>
            <Ad unit='leaderboard' />
        </Responsive> 
        <Responsive {...Responsive.onlyMobile}>
            <Ad unit='mobile leaderboard'/>
        </Responsive> 
    </React.Fragment>;
  }
}

export default ResponsiveLeaderboardAd;