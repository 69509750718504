export function validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function dashDelimited($s)
{
    return $s.replace(/ /g, '-')
             .replace(/\//g, '_');
}

export function escapeChukos(s)
{
    return s.replace(/'/g, "\\'");
}

export function listErrorReasons( error )
{
    const reasons = error.response.data;
    let reasonsList = [];

    if (typeof reasons === 'object')
    {
        for (const key in reasons) {
            if (reasons.hasOwnProperty(key)) {
                const reason = reasons[key];
                reasonsList.push(reason[0]);
            }
        }
    }
    else
    {
        reasonsList.push(error.response);
    }
    

    return reasonsList;
}