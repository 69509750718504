import React, { useState } from 'react';
import { Grid, Form, Button, Modal, Message } from 'semantic-ui-react';
import { useHistory } from "react-router-dom";
import UserService from '../../services/userService';
import { FORM_STATUS } from '../../common/enums';
import ForgotPassword from './forgotPassword';

function LoginModal()
{
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [status, setStatus] = useState(FORM_STATUS.INITIAL);
  const [errorMessages, setErrorMessages] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const history = useHistory();

  const openModal = () => setShowModal( true );
  const closeModal = () => setShowModal( false );

  const onMount = () => {
    setEmail('');
    setPassword('');
    setStatus(FORM_STATUS.INITIAL);
    setErrorMessages([]);
  };

  const handleSubmit = () =>
  {
      setStatus( FORM_STATUS.SENDING );

      UserService.login( email, password)
      .then( () => {
        history.push('/');
        window.location.reload();
      })
      .catch( ( errors ) => {
        setStatus(FORM_STATUS.ERROR);
        setErrorMessages(errors);
      })
  };

  return <Modal open    = { showModal }
                trigger = { <Button className="buttonLink" onClick={openModal}>Login</Button> }
                onMount = { onMount }
                onClose = { closeModal }>
        <Modal.Header>Login</Modal.Header>
            <Modal.Content>
              <Grid centered={true}>
                <Grid.Column width = {16}>
                <Form onSubmit = { handleSubmit }
                      loading = { status === FORM_STATUS.SENDING }
                      error = { status === FORM_STATUS.ERROR } >
                    <Form.Input
                        name='email'
                        value = { email }
                        label='Email'
                        placeholder='email'
                        onChange={ e => setEmail(e.target.value) }
                        autoFocus
                    />
                    <Form.Input
                        name = 'password'
                        type = 'password'
                        value = { password }
                        label='Password'
                        placeholder='Password'
                        onChange={ e => setPassword(e.target.value) }
                    />
                    <Message error header='Login failed' list={ errorMessages } />
                    <Button type='submit' style={{ marginRight: '20px'}}>Login</Button>
                    <ForgotPassword onClose = { closeModal } />
                </Form>
                </Grid.Column>
              </Grid> 
            </Modal.Content>
    </Modal>;
}

export default LoginModal;