import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Button, Form, Message, Image } from 'semantic-ui-react';
import PaymentService from '../../services/paymentService';
import CardSection from './cardSection';

import Logo from '../../images/stripe.svg';

export default function CheckoutForm(props) {
  const [errorMessage, setErrorMessage] = useState(null);
  const [paymentSucceeded, setPaymentSucceeded] = useState( false );
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async event =>
  {
    event.preventDefault();
    
    if (!stripe || !elements) return;

    setPaymentProcessing(true);
    
    PaymentService.createDonationIntent( props.amount ).then((response) => {
      confirmCardPayment(response.data.clientSecret);
    })
    .catch( (e)=>
    {
      setPaymentProcessing(false);
      setErrorMessage(["Could not initiate payment. Please contact us."])
    });
  };

  const confirmCardPayment = async (clientSecret) =>
  {
    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: 'Jenny Rosen', // TODO: Replace? Yeah I should
        },
      }
    });

    if (result.error)
    {
      setErrorMessage([result.error.message]);
      setPaymentSucceeded(false);
    } else 
    {
      if (result.paymentIntent.status === 'succeeded') {
        setErrorMessage(null);
        setPaymentSucceeded(true);
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
      }
    }

    setPaymentProcessing(false);
  }

  return (
    <Form onSubmit={handleSubmit}
          error={ !!errorMessage }
          success={ paymentSucceeded }
          loading={ paymentProcessing }>
      <CardSection />
      <Button disabled={!stripe || paymentSucceeded || paymentProcessing} fluid>Make a Donation</Button>
      <Image src = { Logo } alt="Powered by stripe" size="Medium" className="marginLeftAuto" style={{ marginTop: '5px'}}/>
      <Message error header='Payment failed' list={ errorMessage } />
      <Message success header='Payment Received. Thank you so much for your support!' />
    </Form>
  );
}