import React, { Component } from 'react';
import { Grid, Form, Input, TextArea, Button, Message, Select } from 'semantic-ui-react';
import axios from 'axios';
import { validateEmail } from '../common/utils';
import StandardHelmet from './parts/standardHelmet';

const contactEndPoint = '/api/contact/suggestMove';

const STATUS = {
    INITIAL   : 0,
    VALIDATED : 1,
    SENDING   : 2,
    SUCCESS   : 3,
    ERROR     : 4
}

const SOURCE_OPTIONS = [
    { text: 'my original move', value: 'original'},
    { text: 'just a cool move', value: 'cool'},
    { text: 'A move my school teach', value: 'school'},
    { text: 'Localy known move', value: 'local'},
    { text: 'Globally known move', value: 'global'},
    { text: 'I don\'t know,', value: 'unknown'}
]

class SuggestMove extends Component
{
    state = {
        name    : '',
        email   : '',
        moveName : '',
        source : '',
        videoLink : '',
        videoCreditLink : '',
        description : '',
        status  : STATUS.INITIAL
    }

    handleChange = (_, { name, value }) => this.setState({ [name]: value })

    validate = () =>
    {
        const { name, email, moveName, videoLink } = this.state;
        return (name && email && moveName && videoLink && validateEmail(email) );
    }
    
    handleSubmit = () =>
    {
        const { name, email, moveName, source, videoLink, videoCreditLink, description } = this.state;

        if ( !this.validate() )
        {
            this.setState({
                status : STATUS.VALIDATED
            });
            return;
        }

        const params = {
            Name : name,
            From : email,
            MoveName : moveName,
            Source : source,
            VideoLink : videoLink,
            VideoCreditLink : videoCreditLink,
            Description : description
        };

        this.setState({
            status : STATUS.SENDING
        });

        axios.post(contactEndPoint, params).then( (res) =>
        {
            this.setState({
                name    : '',
                email   : '',
                moveName : '',
                source : null,
                videoLink : '',
                videoCreditLink : '',
                description : '',
                status : STATUS.SUCCESS
            });
        } )
        .catch( () => {
            this.setState({
                status : STATUS.ERROR
            });
        });
    }

    render()
    {
    return <React.Fragment>
        <StandardHelmet title = 'Suggest a Move | Salsa Yo' />
        <Grid centered={true}>
            <Grid.Column width = {16}>
            <div className="lightbox">
                <h1>Suggest a move</h1>
                <p className="textJustify"> 
                    If you are here, you probably have a cool move Salsa Yo doesn't have in its library.
                    We generally accept well known moves, or moves with something unique in them. So if
                    you want to add a new move just fill up the form.
                </p>
            </div>
            <Form onSubmit = { this.handleSubmit }
                success = { this.state.status === STATUS.SUCCESS }
                loading = { this.state.status === STATUS.SENDING }
                warning = { this.state.status === STATUS.VALIDATED }
                error = { this.state.status === STATUS.ERROR } >
                <Form.Field
                    name='name'
                    value = { this.state.name }
                    control = { Input }
                    label='Full Name'
                    onChange={this.handleChange}
                    error= { this.state.status === STATUS.VALIDATED && !this.state.name}
                />
                <Form.Field
                    name='email'
                    value = { this.state.email }
                    control = { Input }
                    label='Email'
                    onChange={this.handleChange}
                    error= { this.state.status === STATUS.VALIDATED && !validateEmail(this.state.email)}
                />
                <Form.Field
                    name='moveName'
                    value = { this.state.moveName }
                    control = { Input }
                    label='Move name'
                    onChange={this.handleChange}
                />
                <Form.Field
                    name='source'
                    value = { this.state.source }
                    control = { Select }
                    label='Source'
                    options = { SOURCE_OPTIONS }
                    onChange={this.handleChange}
                />
                <Form.Field
                    name='videoLink'
                    value = { this.state.videoLink }
                    control = { Input }
                    label='Video link'
                    onChange={this.handleChange}
                    error= { this.state.videoLink === STATUS.VALIDATED && !this.state.videoLink}
                />
                <Form.Field
                    name='videoCreditLink'
                    value = { this.state.videoCreditLink }
                    control={Input}
                    label='Video credit link'
                    onChange={this.handleChange}
                />
                <Form.Field
                    name='description'
                    value = { this.state.description }
                    control={TextArea}
                    label='Description'
                    onChange={this.handleChange}
                />
                <Message success header='Move Submitted' content="The move was submitted." />
                <Message error header='Email Failed' content="The move was not submitted.. This might be a technical issue. please try contacting us in Facebook" />
                <Message warning header='Fields are missing or incorrect' content="Please fill all fields." />
                <Button type='submit'>Submit</Button>
            </Form>
            </Grid.Column>
        </Grid> 
    </React.Fragment>;
    }
}

export default SuggestMove;