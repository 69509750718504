import React, { Component } from 'react';
import { Grid, Form, Input, Button, Message, Modal } from 'semantic-ui-react';
import UserService from '../../services/userService';
import { validateEmail } from '../../common/utils';
import { FORM_STATUS } from '../../common/enums';

class ForgotPassword extends Component
{
    constructor(props) {
        super(props);
        this.emailTextBox = React.createRef();
        this.modalMounted = this.modalMounted.bind(this);
      }

    state = {
        email   : '',
        status  : FORM_STATUS.INITIAL,
        errorMessages : [],
        errorFields : [],
        showModal : false
    }
    
    openModal = () => this.setState({showModal : true});
    closeModal = () => {
        this.setState({showModal : false});
        if (this.props.onClose) this.props.onClose();
    }

    modalMounted() 
    {
        if (this.emailTextBox.current)
        {
          this.emailTextBox.current.focus();
        }        
    }

    handleChange = (_, { name, value }) => this.setState({ [name] : value })
    handleCheck = (_, { name, checked }) => this.setState({ [name] : checked })

    validate = () =>
    {
        const { email } = this.state;

        let valid = true;
        const errorFields = [];
        const messages = [];

        const listError = ( field, message ) => {
            valid = false;
            errorFields.push( field );
            messages.push( message );
        }

        if ( !validateEmail( email) ) listError( 'email', 'Invalid email.' );

        this.setState({
            status : FORM_STATUS.VALIDATED,
            errorFields,
            errorMessages : messages
        });

        return valid;
    }

    handleSubmit = () =>
    {
        if ( !this.validate() ) return;

        this.setState( { status : FORM_STATUS.SENDING } );
        UserService.forgotPassword( this.state.email )
                    .then( () => 
                    {
                        this.closeModal();
                    })
                    .catch( ( errors ) =>
                    {
                        this.setState( { 
                            status       : FORM_STATUS.ERROR,
                            errorMessages : errors,
                            errorFields  : []
                        } );
                    });
    }

    render()
    {
    return <Modal open    = {this.state.showModal}
                  trigger = {<Button className="buttonLink" onClick={this.openModal}>Forgot your password?</Button>}
                  onMount = { this.modalMounted }
                  onClose = { this.closeModal }>
        <Modal.Header>Forgot your password?</Modal.Header>
        <Modal.Content>
            {
            <React.Fragment>
            <p>Please fill the email you have created the account with. An email to reset your password will shortly follow.</p>
                <Grid centered={true}>
                    <Grid.Column width = {16}>
                        <Form onSubmit = { this.handleSubmit }
                            loading = { this.state.status === FORM_STATUS.SENDING }
                            error = { this.state.status === FORM_STATUS.ERROR }
                            warning = { this.state.status === FORM_STATUS.VALIDATED }
                            success = { this.state.status === FORM_STATUS.SUCCESS }>
                            <Form.Field
                                name        = 'email'
                                value       = { this.state.email }
                                control     = { Input }
                                label       = 'Email'
                                placeholder = 'email'
                                onChange    = {this.handleChange}
                                error       = { this.state.status === FORM_STATUS.VALIDATED && this.state.errorFields.includes('email')}
                            />
                            
                            <Message success header='You should receive soon an email with further instructions on how to reset your password' />
                            <Message warning header='Fields are missing or incorrect' list={ this.state.errorMessages } />
                            <Message error header='Reset password Failed' list={ this.state.errorMessages } />
                            <Button type='submit'>Reset my password</Button>
                        </Form>
                    </Grid.Column>
                </Grid> 
            </React.Fragment>
            }
        </Modal.Content>
    </Modal>
    }
}

export default ForgotPassword;