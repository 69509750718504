import React, { Component } from 'react';
import WebsiteMessagePage from '../parts/websiteMessagePage';
import LoginModal from './loginModal';

class ResetPasswordComplete extends Component
{

  render()
  {
    return <WebsiteMessagePage icon = "sync alternate"
                               title = "Your password was changed"
                               content = { <React.Fragment>
                                  You have successfully changed your password.
                                  <br/><br/>
                                  You may now <LoginModal/>
                               </React.Fragment>}
            />;
    }
}

export default ResetPasswordComplete;