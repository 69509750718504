import React, { Component } from 'react';
import { Grid, Form, Input, Button, Message } from 'semantic-ui-react';
import StandardHelmet from '../parts/standardHelmet';
import UserService from '../../services/userService';
import { FORM_STATUS } from '../../common/enums';
import ResetPasswordComplete from './resetPasswordComplete';
import queryString from 'query-string';

class ResetPassword extends Component
{
    state = {
        password : '',
        passwordRepeat : '',
    }
    
    handleChange = (_, { name, value }) => this.setState({ [name] : value })
    handleCheck = (_, { name, checked }) => this.setState({ [name] : checked })

    validate = () =>
    {
        const { password, passwordRepeat } = this.state;

        let valid = true;
        const errorFields = [];
        const messages = [];

        const listError = ( field, message ) => {
            valid = false;
            errorFields.push( field );
            messages.push( message );
        }

        if ( password.length < 8 ) listError( 'password', 'Password should be at least 8 characters' );
        if ( password !== passwordRepeat ) listError( 'passwordRepeat', 'Password and the re-password do not match.' );

        this.setState({
            status : FORM_STATUS.VALIDATED,
            errorFields,
            errorMessages : messages
        });

        return valid;
    }

    handleSubmit = () =>
    {
        if ( !this.validate() ) return;

        const { userId, token } = queryString.parse(this.props.location.search);

        this.setState( { status : FORM_STATUS.SENDING } );
        UserService.resetPassword( userId, 
                                   token,
                                   this.state.password )
                    .then( ( a ) => 
                    {
                        this.setState( { 
                            status : FORM_STATUS.SUCCESS,
                        } );
                    })
                    .catch( ( errors ) =>
                    {
                        this.setState( { 
                            status       : FORM_STATUS.ERROR,
                            errorMessages : errors,
                            errorFields  : [] // From server no errors on specific fields
                        } );
                    });
    }

    render()
    {
    return <React.Fragment>
        <StandardHelmet title = 'Reset password | Salsa Yo' />
        {
            (this.state.status === FORM_STATUS.SUCCESS) ? <ResetPasswordComplete/> :
            <Grid centered={true}>
                <Grid.Column width = {16}>
                <p>In order to reset your password, please type in your new password.</p>
                <Form onSubmit = { this.handleSubmit }
                    loading = { this.state.status === FORM_STATUS.SENDING }
                    error = { this.state.status === FORM_STATUS.ERROR }
                    warning = { this.state.status === FORM_STATUS.VALIDATED }
                    success = { this.state.status === FORM_STATUS.SUCCESS }>
                    <Form.Field
                        type        = 'password'
                        name        = 'password'
                        value       = { this.state.password }
                        control     = { Input }
                        label       = 'Password'
                        placeholder = 'Password'
                        onChange    = {this.handleChange}
                        error       = { this.state.status === FORM_STATUS.VALIDATED && this.state.errorFields.includes('password')}
                    />
                    <Form.Field
                        type        = 'password'
                        name        = 'passwordRepeat'
                        value       = { this.state.passwordRepeat }
                        control     = { Input }
                        label       = 'Repeat Password'
                        placeholder = 'Password'
                        onChange    = {this.handleChange}
                        error       = { this.state.status === FORM_STATUS.VALIDATED && this.state.errorFields.includes('passwordRepeat')}
                    />
                    <Message success header='Password was reset Successfully.' />
                    <Message warning header='Fields are missing or incorrect' list={ this.state.errorMessages } />
                    <Message error header='Reseting the password failed' list={ this.state.errorMessages } />
                    <Button type='submit'>Set the new password</Button>
                </Form>
                </Grid.Column>
            </Grid> 
        }
    </React.Fragment>;
    }
}

export default ResetPassword;