import React from 'react';
import { Advertisement } from 'semantic-ui-react';
import AdSense from 'react-adsense';

class Ad extends React.Component
{
  state = {
    hasError: false
  }

  static getDerivedStateFromError()
  {
    // Fix for adblocker raising an uncaught exception.
    return { hasError: true };
  }

  render()
  {
    if (this.state.hasError) return null;

    return <Advertisement unit={this.props.unit} className="marginCenter">
        <AdSense.Google
            client='ca-pub-2538262111988184'
            slot='7458789366'
            // style={{ display: 'block' }}
            // layout='in-article'
            format='auto'
        />
    </Advertisement>
  }
}

export default Ad;