import React, { Component } from 'react';
import { Grid, Form, Input, TextArea, Button, Message } from 'semantic-ui-react';
import axios from 'axios';
import { validateEmail } from '../common/utils';
import StandardHelmet from './parts/standardHelmet';
import queryString from 'query-string';

const contactEndPoint = '/api/contact/message';

const STATUS = {
    INITIAL   : 0,
    VALIDATED : 1,
    SENDING   : 2,
    SUCCESS   : 3,
    ERROR     : 4
}
class Contact extends Component
{
    state = {
        name    : '',
        email   : '',
        subject : '',
        message : '',
        status  : STATUS.INITIAL
    }

    componentDidMount()
    {
        const { subject } = queryString.parse(this.props.location.search);
        if (subject)
        {
            this.setState({
                subject : subject
            })
        }
    }

    handleChange = (_, { name, value }) => this.setState({ [name]: value })

    validate = () =>
    {
        const { name, email, subject, message } = this.state;
        return (name && email && subject && message && validateEmail(email) );
    }
    
    handleSubmit = () =>
    {
        const { name, email, subject, message } = this.state;

        if ( !this.validate() )
        {
            this.setState({
                status : STATUS.VALIDATED
            });
            return;
        }

        const params = {
            Name : name,
            From : email,
            Subject : subject,
            Message : message
        };

        this.setState({
            status : STATUS.SENDING
        });

        axios.post(contactEndPoint, params).then( (res) =>
        {
            this.setState({
                name    : '',
                email   : '',
                subject : '',
                message : '',
                status : STATUS.SUCCESS
            });
        } )
        .catch( () => {
            this.setState({
                status : STATUS.ERROR
            });
        });
    }

    render()
    {
    return <React.Fragment>
        <StandardHelmet title = 'Contact | Salsa Yo' />
        <Grid centered={true}>
            <Grid.Column width = {16}>
            <div className="lightbox">
                <h1>Contact</h1>
                <p className="textJustify"> 
                    Feel free to contact me on any subject you wish whether it's suggestions for the site, if you want to correct a 
                    mistake on the site or just say what's on your mind. Follow here if you want to suggest a new move. 
                </p>
            </div>
            <Form onSubmit = { this.handleSubmit }
                success = { this.state.status === STATUS.SUCCESS }
                loading = { this.state.status === STATUS.SENDING }
                warning = { this.state.status === STATUS.VALIDATED }
                error = { this.state.status === STATUS.ERROR } >
                <Form.Field
                    name='name'
                    value = { this.state.name }
                    control = { Input }
                    label='Full Name'
                    placeholder='Full Name'
                    onChange={this.handleChange}
                    error= { this.state.status === STATUS.VALIDATED && !this.state.name}
                />
                <Form.Field
                    name='email'
                    value = { this.state.email }
                    control = { Input }
                    label='Email'
                    placeholder='Email'
                    onChange={this.handleChange}
                    error= { this.state.status === STATUS.VALIDATED && !validateEmail(this.state.email)}
                />
                <Form.Field
                    name='subject'
                    value = { this.state.subject }
                    control = { Input }
                    label='Subject'
                    placeholder='Subject'
                    onChange={this.handleChange}
                    error= { this.state.status === STATUS.VALIDATED && !this.state.subject}
                />
                <Form.Field
                    name='message'
                    value = { this.state.message }
                    control={TextArea}
                    label='Message'
                    onChange={this.handleChange}
                    error= { this.state.status === STATUS.VALIDATED && !this.state.message}
                />
                <Message success header='Email Sent' content="The email was sent successfully." />
                <Message error header='Email Failed' content="The email was no sent. This might be a technical issue. please try contacting us in Facebook" />
                <Message warning header='Fields are missing or incorrect' content="Please fill all fields." />
                <Button type='submit'>Submit</Button>
            </Form>
            </Grid.Column>
        </Grid> 
    </React.Fragment>;
    }
}

export default Contact;