import axios from 'axios';
import { listErrorReasons } from '../common/utils';

const ENDPOINT_CREATE_DONATION_INTENT = '/api/payment/createDonationIntent';

class PaymentService
{
    createDonationIntent( amount )
    {
       let data = JSON.stringify({ amount });

       return new Promise( (resolve, reject) =>
       {
            axios.post(ENDPOINT_CREATE_DONATION_INTENT, data).then( ( clientSecret ) =>
            {
                resolve( clientSecret );
            } ).catch( ( error ) =>
            {           
                const reasons = listErrorReasons( error );
                reject( reasons )
            });
       });
    }
}

export default new PaymentService();