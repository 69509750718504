import React, { Component } from 'react';
import { dashDelimited } from '../common/utils';
import axios from 'axios';
import { Grid, Loader } from 'semantic-ui-react';
import YouTube from 'react-youtube';
import { Link } from "react-router-dom";
import { Levels } from '../common/fields';

const dailyMoveEndPoint = '/api/moves/dailyMove';
class Index extends Component
{

  state = {
    dailyMove: null,
  }

  dailyMoveVideoOptions = {
    playerVars : 
      {
        autoplay : 1,
        mute: 1,
        controls : 0,
        loop : 1,
        fs : 0,
        modestbranding : 1
      }
  }

  componentDidMount()
  {    
      axios.get(dailyMoveEndPoint).then( (res) =>
      {
          this.setState({dailyMove : res.data});
      } );
  };

  render()
  {
    const dailyMove = this.state.dailyMove;

    return <Grid centered={true} stackable>
      <Grid.Row>
        <Grid.Column width={16} textAlign="center">
          <h2 className="siteSubtitle">
            Ever wanted to know how to salsa? Need a reminder of the moves taught in your salsa dance class? <br />
            Salsa Yo is just for you! The most comprehensive salsa moves list offered on the web.
          </h2>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={8} textAlign="center">
          <h2>
            Move of the day
          </h2>
          <Loader size='big' active={!dailyMove} inline="centered" className="marginTopMedium" />
          {
            dailyMove && <div>
              <div className="videoRatioBox">
                <YouTube videoId={ dailyMove.video } opts = { this.dailyMoveVideoOptions }  />
              </div> 
              <p>
                See <Link to={`/move/${dashDelimited(dailyMove.name)}`}>{ dailyMove.name }</Link> for further details
              </p>
            </div> 

          }
        </Grid.Column>
        <Grid.Column  width={8} textAlign="center">
            <h2>
              Browse moves by level
            </h2>
            {
              Levels.map( level => <Link to={`/moves?level=${level.value}`} className="rounded-button-link" key={level.value}>
                { `${level.value} - ${level.text}` }
            </Link>)
            }
        </Grid.Column>
      </Grid.Row>
    </Grid> ;
  }
}

export default Index;