import lib from './displayUtils';

const LEVELS = {
    1 : 'Basics',
    2 : 'Beginner',
    3 : 'Improver',
    4 : 'Intermediate',
    5 : 'Advanced',
    6 : 'Expert',
    7 : 'Master'
}

export function booleanToYesNo( bool )
{
    return ( bool ) ? 'Yes' : 'No';
}

export function stringBooleanToYesNo( boolString )
{
    return booleanToYesNo( boolString === "1");
}

export function displayDanceConfiguration( configuration, short = false )
{
    if ( configuration === 1)
    {
        return (short) ? 'Couple' : 'Couple dance';
    }
    else if ( configuration === 2)
    {
        return (short) ? 'Rueda' : 'Rueda Only';
    }

    return '';
}

export function displayLevel( levelNumber )
{
    if ( levelNumber < 1 || levelNumber > 7 )
    {
        throw new Error(`Unknown level received: ${ levelNumber }`);
    }
    return LEVELS[ levelNumber ];
}

export function displayBarCount( barCounts )
{
    if ( barCounts === 100 ) return "Se Fue";

    return barCounts;
}

export default lib;