import React, { Component } from 'react';
import { Grid, List } from 'semantic-ui-react';
import { Link } from "react-router-dom";
import SocialBar from './parts/socialBar';
import StandardHelmet from './parts/standardHelmet';
class Contribute extends Component
{

  render()
  {
    return <React.Fragment>
        <StandardHelmet title = 'Contribute | Salsa Yo'
                        description = 'You could contribute to our the Salsa Yo project in many ways.' />
        <Grid centered={true}>
            <Grid.Column width = {16}>
                <div className="lightbox">
                    <h1>Contribute</h1>
                    <p className="textJustify"> 
                        Do you like the site and the project? Want to contribute to the site growth? <br/>
                        Here are couple of ways you can help Salsa Yo! grow and develop.
                    </p>
                </div>

                <div className="center">
                    <div className="section">
                        <h2>Token of appreciation</h2>
                        <p className="textJustify">
                            The project is here for the sake of knowledge sharing and spreading the love. Maintaining the website however
                            does come with costs of time and money that come from my personal budget. I am glad to invest it in order
                            to help our worldwide Salsa community to grow. Still, if you want to help with the costs it will be greatly
                            appretiated, even if it's just 1 Euro or Dollar.
                        </p>
                        <Link to="/support" style={{ fontWeight:"bold", fontStyle:"italic", fontSize:"1.15em"}}>Support the project & Donate</Link>
                    </div>

                    <div>
                        <h2>Take part in the project</h2>
                        <p className="section textJustify"> You can help us shape & grow the project! </p>
                        <p className="section textJustify">
                            <List bulleted>
                                <List.Item>Do you know a new move that is not presented here?</List.Item>
                                <List.Item>Do you want to write descriptions for our moves?</List.Item>
                                <List.Item>Do you want a great idea for the project?</List.Item>
                                <List.Item>
                                    Are you a talented web graphic designer, or a developer and want to
                                    improve or implement new features for the project?
                                </List.Item>
                            </List>
                        </p>
                    </div>

                    <div className="section">
                        <h2>Share the love, tell your friends!</h2>
                        <p className="textJustify">
                            Spread the word and tell your friends about SalsaYo.com. Whether they are experienced dancers, 
                            beginners or might think of giving salsa, the project is helpful to all, beginners, teachers, and schools.
                            All the moves presented here are meant to be shared :)
                            Feel free to share or teach others.
                            Easiest way would be through social networks such as Facebook, Twitter or Myspace.
                        </p>
                        <SocialBar/>
                    </div>    
                </div>
            </Grid.Column>
        </Grid> ;
    </React.Fragment>
  }
}

export default Contribute;