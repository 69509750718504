import React, { Component } from 'react';
import { Grid, List, Icon } from 'semantic-ui-react';
import { Link } from "react-router-dom";
import StandardHelmet from './parts/standardHelmet';

class MoveExplanation extends Component
{

  render()
  {
    return <React.Fragment>
        <StandardHelmet title = 'How to read our Salsa moves entries | Salsa Yo'
                        description = 'This should explainn how to read each of our Salsa Moves entries.' />
        <Grid centered={true}>
            <Grid.Column width = {10}>
                <div className="lightbox">
                    <h1>How to read each move</h1>
                    <p className="textJustify"> 
                        In Salsa Yo we try to create a standard for Cuban Salsa moves. Each move we choose
                        to put in our data base is either a signature move ( a move many people know ) or
                        has something uniuqe in it. That said, it's important to know that each local scene
                        might have a slightly different standard.
                    </p>
                </div>

                <div>
                    <h2>Level</h2>
                    <p className="textJustify">
                        The level represent the complexity of a move. We categorize the moves roughly with these
                        guidelines:
                        <List bulleted>
                            <List.Item>
                                <Link to={{ pathname: '/moves', search: '?level=1' }} className="bold">Basics</Link> - 
                                The most basic elements of Salsa Cubana. Moves you'd expect to see in the first few
                                lessons.
                            </List.Item>
                            <List.Item>
                                <Link to={{ pathname: '/moves', search: '?level=2' }} className="bold">Beginners</Link> - 
                                Building up from basics, and enchancing some of the basic moves. Largely
                                based on <Link to='/moves/4'>Enchufala</Link>, and the  <Link to='/moves/102'>Back Rocks</Link> steps.
                            </List.Item>
                            <List.Item>
                                <Link to={{ pathname: '/moves', search: '?level=3' }} className="bold">Improvers</Link> - 
                                Largely contains what we define as "Core moves", moves that build up the foundation of Salsa
                                Cubana, for example <Link to='/moves/37'>Vacilala</Link>. You can also find moves that enhance 
                                Beginner level moves. For example, <Link to='/moves/28'>El Uno</Link>.
                            </List.Item>
                            <List.Item>
                                <Link to={{ pathname: '/moves', search: '?level=4' }} className="bold">Intermediate</Link> - 
                                Mostly harder core moves or staple moves that are used frequently and are building up from 
                                Core moves. For example, <Link to='/moves/51'>Sombrero</Link>. You can also find moves that 
                                enhance Improvers level moves. For example , <Link to='/moves/48'>El Dedo</Link>.
                            </List.Item>
                            <List.Item>
                                <Link to={{ pathname: '/moves', search: '?level=5' }} className="bold">Advanced</Link> - 
                                Combination moves that are not hard, and include a small amount of relatively easy small
                                tricks.
                            </List.Item>
                            <List.Item>
                                <Link to={{ pathname: '/moves', search: '?level=6' }} className="bold">Expert</Link> - 
                                Harder combination moves. Would often use at least one trick that is more unique or
                                harder to perform.
                            </List.Item>
                            <List.Item>
                                <Link to={{ pathname: '/moves', search: '?level=7' }} className="bold">Master</Link> - 
                                The highest level of moves. They either contain a large number of tricks, a
                                difficult to perform trick, or have a hard transition between its elements.
                            </List.Item>
                        </List>

                        <h2>Synonyms</h2>
                        <p className="textJustify"> 
                            As much as we'd like, there is no true one standard across the world regarding Cuban Salsa
                            moves. Sometimes the same move (or nearly the same move) has different names in different
                            local scenes. We mention under this column, some of the names we have found for the move.
                            There might be more however, it's impossible to follow all the local scenes in the world.
                        </p>

                        <h2>Move Type</h2>
                        <p className="textJustify"> 
                            Indicates whether the move is meant to be used in a couple dance or in a Rueda de Casino.
                            All Cuban Salsa moves can be used in Rueda de Casino. But a Rueda Move is a move that is 
                            meant predominately to Rueda de Casino. It usually means one of the two:
                            <List bulleted>
                                <List.Item>
                                    More than one couple is needed to perform the move. For example, <Link to='/moves/10'>Dame</Link>.
                                </List.Item>
                                <List.Item>
                                    It contains elements that are not meant for a couple dance. For example the clap in
                                    <Link to='/moves/40'> Un Fly</Link>.
                                </List.Item>
                            </List>
                        </p>

                        <h2>Rueda Sign</h2>
                        <p className="textJustify"> 
                            Rueda de Casino moves have a name to be called, and a visual sign to help dancers understand
                            what move is being called when it's too loud. This is a description of the sign. Not all moves
                            have a known visual sign.
                        </p>

                        <h2>Starts With</h2>
                        <p className="textJustify"> 
                            If the move starts with a known move we will mention it under this column.
                        </p>

                        <h2>Based On</h2>
                        <p className="textJustify"> 
                            If the move is an enhancement of another move, then we say it is based on it.
                        </p>

                        <h2>Bar Counts</h2>
                        <p className="textJustify"> 
                            A bar for dancers means the one count of 1 to 8, or as many know it "1,2,3 - 5,6,7".
                            Basically if you count it 3 times before a move is done then the move is 3 bars long. We
                            do not count <Link to='/moves/1'>Guapea</Link> in the beginning of the move, or a 
                            <Link to='/moves/3'> Dile Que No</Link> in the end of the move.
                        </p>

                        <h2>Videos</h2>
                        <p className="textJustify"> 
                            We select our videos carefully in order to make sure they fit the standard and provide good
                            information. They are also ordered by they are ordered more or less by their quality.
                        </p>
                        <p>
                            Some videos have Icons:
                            <br/>
                            <Icon name = "star" /> Favorite - Our favorite video. Usually its the best we know in term of quality of
                            the video, performance of the move, and how concise is it.
                            <br/>
                            <Icon name = "graduation cap" /> Insturctional - These videos include verbal instructional content.
                        </p>
                    </p>
                </div>
            </Grid.Column>
            </Grid> 
    </React.Fragment>;
  }
}

export default MoveExplanation;