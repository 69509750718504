import React, { Component } from "react";
import { map } from 'lodash';
import {
  Container,
  Icon,
  Image,
  Menu,
  Sidebar,
  Responsive
} from "semantic-ui-react";
import LogoSymbol from '../images/logo-symbol.png';

const NavBarMobile = ({
  children,
  leftItems,
  rightItems,
  onPusherClick,
  onToggle,
  visible
}) => (
  <Sidebar.Pushable>
    <Sidebar
      as={Menu}
      animation="overlay"
      icon="labeled"
      vertical
      visible={visible}
      style={{overflowY: "visible !important"}}
      onClick={onPusherClick}
    >
      {map(leftItems, item => <Menu.Item {...item}  />)}
    </Sidebar>
    <Sidebar.Pusher
      dimmed={visible}
      onClick={onPusherClick}
      style={{ minHeight: "100vh" }}
    >
      <Menu fixed="top">
        <Menu.Item>
          <Image size="mini" src={LogoSymbol} alt="Salsa Yo Symbol" />
        </Menu.Item>
        <Menu.Item onClick={onToggle}>
          <Icon name="sidebar" />
        </Menu.Item>
        <Menu.Menu position="right">
          {map(rightItems, item => <Menu.Item {...item} />)}
        </Menu.Menu>
      </Menu>
      {children}
    </Sidebar.Pusher>
  </Sidebar.Pushable>
);

const NavBarDesktop = ({ leftItems, rightItems }) => (
  <Menu fixed="top">
    <Menu.Item>
    <Image size="mini" src={LogoSymbol} alt="Salsa Yo Logo" />
    </Menu.Item>
    {map(leftItems, item => <Menu.Item {...item} />)}
    <Menu.Menu position="right">
      {map(rightItems, item => <Menu.Item {...item} />)}
    </Menu.Menu>
  </Menu>
);

const NavBarChildren = ({ children }) => (
  <Container style={{ marginTop: "3em" }}>{children}</Container>
);

export default class ResponsiveNavBar extends Component {
  state = {
    visible: false
  };

  handlePusher = () => {
    const { visible } = this.state;

    if (visible) this.setState({ visible: false });
  };

  handleToggle = () => this.setState({ visible: !this.state.visible });

  render() {
    const { children, leftItems, rightItems, sidebarItems } = this.props;
    const { visible } = this.state;

    return (
      <div>
        <Responsive maxWidth = { 837 }>
          <NavBarMobile
            leftItems={sidebarItems}
            rightItems={rightItems}
            onPusherClick={this.handlePusher}
            onToggle={this.handleToggle}
            visible={visible}
          >
            <NavBarChildren>{children}</NavBarChildren>
          </NavBarMobile>
        </Responsive>
        <Responsive minWidth = { 837 }>
          <NavBarDesktop leftItems={leftItems} rightItems={rightItems} />
          <NavBarChildren>{children}</NavBarChildren>
        </Responsive>
      </div>
    );
  }
}