import React, { Component } from 'react';
import _ from 'lodash';
import { Table, Grid, Pagination, Input, Dropdown, Icon, Loader, Responsive } from 'semantic-ui-react';
import axios from 'axios';
import queryString from 'query-string';
import ResponsiveLeaderboardAd from '../components/responsiveLeaderboardAd';
import { Link } from "react-router-dom";
import { dashDelimited } from '../common/utils';
import { displayDanceConfiguration, displayLevel, displayBarCount } from '../common/displayUtils';
import { Levels, GroupedBarsLength, BasedOn, StartsWith, DanceConfiguration, FavoriteOptions } from '../common/fields';
import StandardHelmet from './parts/standardHelmet';
import userService from '../services/userService';

const { Header, Cell, Row, Body, HeaderCell } = Table;

const CancelToken = axios.CancelToken;
const apiEndpoint = '/api/moves';

const ORDER_BY_DEFAULT = 'name';
const DIRECTION_DEFAULT = 'asc';
const PAGE_DEFAULT = 1;
class Moves extends Component
{
    state = {
        moves            : [],
        page             : null,
        loadingMoves     : false,
        filter           : {},
        fetchMovesSource : null
    }

    componentDidMount()
    {
        this.fetchMoves = _.debounce( this.fetchMoves, 100);

        this.setUrlToState();
        this.fetchMoves();
    };

    componentWillReceiveProps(nextProps)
    {
        if (nextProps.location !== this.props.location)
        {
            this.setUrlToState( nextProps );
        }
    }

    componentDidUpdate(nextProps)
    {
        if (nextProps.location !== this.props.location)
        {
            this.fetchMoves();
        }
    }

    setUrlToState( props = this.props)
    {
        const { orderBy, direction, page, counts, level, name, startsWith, basedOn, danceConfiguration, favorite } = queryString.parse(props.location.search);
        let fullDirection = "ascending";
        if ( direction ) 
        {
            fullDirection = ( direction === "asc") ? "ascending" : "descending";
        }
        
        this.setState( { 
            orderBy   : orderBy || ORDER_BY_DEFAULT,
            direction : fullDirection,
            page : parseInt(page) || PAGE_DEFAULT,
            filter : {
                counts : counts || '',
                level : parseInt(level) || '',
                name : name || '',
                startsWith : startsWith || '',
                basedOn : basedOn || '',
                danceConfiguration : parseInt(danceConfiguration) || '',
                favorite : (favorite > -1) ? parseInt(favorite) : null
            }
        } );
    }

    getUrlParams()
    {
        return queryString.parse(this.props.location.search);
    }

    navigate( queryParams )
    {
        const newQueryParams = queryString.stringify( queryParams );

        this.props.history.push({
            pathname: '/moves',
            search: newQueryParams
        });
    } 

    fetchMoves( props = this.props )
    {
        const apiEndPointQuery = `${apiEndpoint}${props.location.search}`;

        if ( this.state.fetchMovesSource )
        {
            this.state.fetchMovesSource.cancel('New search abound.');
        }

        const cancelSource = CancelToken.source();
        this.setState({
            moves            : [],
            page             : null,
            loadingMoves     : true,
            fetchMovesSource : cancelSource
        })
        
        axios.get(apiEndPointQuery, { cancelToken : cancelSource.token }).then( (res) =>
        {
            const { moves, pages, currentPage } = res.data;
            this.setState({ 
                moves,
                page         : parseInt(currentPage),
                pages        : parseInt( pages ),
                loadingMoves : false });
        } ).catch( (e) => {
            if (!axios.isCancel(e)) {
                throw e;
            }
        });
    }

    filter( column, value )
    {
        const queryParams = this.getUrlParams();

        if ( value === null || value === false || value === '' )
        {
            delete queryParams[ column ];
        } 
        else
        {
            queryParams[ column ] = value;
        }

        delete queryParams.page;

        this.navigate( queryParams );
    }

    hasFilter()
    {
        return this.state.filter &&
        (this.state.filter.counts || this.state.filter.level || this.state.filter.name ||
         this.state.filter.startsWith || this.state.filter.basedOn || this.state.filter.danceConfiguration ||
         this.state.filter.favorite !== null);
    }

    onPageChange( page )
    {
        const queryParams = this.getUrlParams();
        queryParams.page = page;
        this.navigate( queryParams );
    }

    sortTable( column )
    {
        const queryParams = this.getUrlParams();

        if ( !queryParams.orderBy )
        {
            queryParams.orderBy = ORDER_BY_DEFAULT;
            queryParams.direction = "desc";
        }
        if ( queryParams.orderBy === column )
        {
            queryParams.direction = ( queryParams.direction === "asc") ? "desc" : "asc";
        }
        else
        {
            queryParams.orderBy = column;
            queryParams.direction = DIRECTION_DEFAULT;
        }

        this.navigate( queryParams );
    }

    getPaginationRange()
    {
        return (window.innerWidth >= Responsive.onlyMobile.maxWidth) ? 5 : 3;
    }

    getTagsIcons( tags )
    {
        if (tags.find( t => t.category === 1))
        {
            return <Icon name="star" color="yellow" className="movesTableIcon"/>
        }
    }

    render()
    {
        const Columns = 
        [
            {
                columnName  : 'favorite',
                displayName : <Icon name="star" color="white" className="movesTableIcon" />,
                style       : { paddingRight : '0px' }
            },
            {
                columnName  : 'name',
                displayName : 'Move'
            },
            {
                columnName  : 'level',
                displayName : 'Level'
            },
            {
                columnName  : 'counts',
                displayName : 'Bars',
                textAlign   : 'center'
            },
            {
                columnName  : 'danceConfiguration',
                displayName : 'Type',
                textAlign   : 'center'
            },
            {
                columnName  : 'startsWith',
                displayName : 'Starts with'
            },
            {
                columnName  : 'basedOn',
                displayName : 'Based On'
            }
        ];

        return <React.Fragment>
            <StandardHelmet title = 'Browse Salsa Moves | Salsa Yo'
                            description = 'Search and browse our Salsa moves encyclopedia. Each figure will be explained and include a video.' />
            <Grid centered={true} stackable>
                <Grid.Row>
                    <Grid.Column width={3}> 
                    {
                        this.state.filter && 
                        <Grid.Row>
                            <Grid>
                                <Grid.Column className = "filterContainer" >
                                    <Grid.Row>
                                        <h3 className ="filterHeader">
                                            Filter
                                        </h3>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Input icon='search' placeholder='Search Move...' fluid
                                            onChange={ ( _, data ) => this.filter('name', data.value)}
                                            value={this.state.filter.name} />
                                        <Dropdown selection clearable fluid
                                            placeholder='Select Level'
                                            options = { Levels }
                                            onChange = { ( _, data ) => this.filter('level', data.value)}
                                            value = { this.state.filter.level}/>
                                        <Dropdown selection clearable fluid
                                            placeholder='Select Length'
                                            options = { GroupedBarsLength }
                                            onChange={ ( _, data ) => this.filter('counts', data.value)}
                                            value = {  this.state.filter.counts} />        
                                        <Dropdown selection clearable fluid
                                            placeholder='Starts With' 
                                            options = { StartsWith }
                                            onChange={ ( _, data ) => this.filter('startsWith', data.value)}
                                            value = {  this.state.filter.startsWith}/>
                                        <Dropdown selection clearable fluid
                                            placeholder='Based On'
                                            options = { BasedOn }
                                            onChange={ ( _, data ) => this.filter('basedOn', data.value)}
                                            value = {  this.state.filter.basedOn}/>
                                        <Dropdown selection clearable fluid
                                            placeholder='Move Type'
                                            options = { DanceConfiguration }
                                            onChange={ ( _, data ) => this.filter('danceConfiguration', data.value)}
                                            value = {  this.state.filter.danceConfiguration} />
                                        { userService.isLoggedIn() && 
                                          <Dropdown selection clearable fluid
                                            placeholder='Favorite?'
                                            options = { FavoriteOptions }
                                            onChange={ ( _, data ) => this.filter('favorite', data.value)}
                                            value = { this.state.filter.favorite}
                                          />
                                        }
                                    </Grid.Row>
                                    <Grid.Row textAlign="center" className="paddingTop">
                                        {
                                            this.hasFilter() &&
                                            <Link to="/moves">
                                                <Icon name="delete"/>Clear
                                            </Link>
                                        }
                                    </Grid.Row>
                                </Grid.Column>
                            </Grid>
                        </Grid.Row>
                    }
                    </Grid.Column>
                    <Grid.Column width={13} textAlign="center">
                        {
                            this.state.loadingMoves &&
                            <Loader size='massive' active={this.state.loadingMoves} className="marginTopHuge">
                                Searching...
                            </Loader>
                        }
                        {
                            !this.state.loadingMoves && this.state.moves.length === 0 &&
                            <div className="marginTopHuge colorStrongBlue">
                                <Icon name='meh outline' size='massive' className="colorMediumBlue"/>
                                <p className="marginTopMedium">
                                    There are no moves that match the criteria. Please change the filter.
                                </p>
                                <p>
                                    <Link to="/moves">
                                        <Icon name="delete"/>Clear Filter
                                    </Link>
                                </p>
                            </div>
                        }
                        {
                            !this.state.loadingMoves && this.state.moves.length > 0 &&
                            <React.Fragment>
                                <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                                    <Table sortable basic={true} >
                                        <Header>
                                            <Row>
                                            {
                                                Columns.map( c => <HeaderCell
                                                    key = { `header_${c.columnName}` }
                                                    textAlign = { c.textAlign || "left"}
                                                    sorted = { this.state.orderBy === c.columnName ? this.state.direction : null }
                                                    onClick = { () => this.sortTable( c.columnName ) }
                                                    style = { c.style }
                                                    >
                                                    { c.displayName }
                                                </HeaderCell>)
                                            }
                                            </Row>
                                        </Header>
                                        <Body>
                                        {
                                            this.state.moves.map( ({ id, name, level, counts, danceConfiguration, startsWith, basedOn, tags }) => (
                                                <Row key = { name }>
                                                    <Cell style={{ paddingRight: "0px"}}> { this.getTagsIcons(tags) } </Cell>
                                                    <Cell> <Link to={`/move/${dashDelimited(name)}`}>{ name }</Link> </Cell>
                                                    <Cell> { displayLevel( level ) } </Cell>
                                                    <Cell textAlign="center"> { displayBarCount(counts) } </Cell>
                                                    <Cell textAlign="center"> { displayDanceConfiguration( danceConfiguration, true ) } </Cell>
                                                    <Cell> { startsWith } </Cell>
                                                    <Cell> { basedOn } </Cell>
                                                </Row>
                                            ) )
                                        }
                                        </Body>
                                    </Table>
                                </Responsive>
                                <Responsive {...Responsive.onlyMobile}>
                                    <Grid columns={2}>
                                    {
                                        this.state.moves.map( ({ name, tags }) => (
                                            <Grid.Column key = { name } padded>
                                                <p>
                                                    { this.getTagsIcons(tags) }
                                                    <Link to={`/move/${dashDelimited(name)}`}>{ name }</Link>
                                                </p>
                                            </Grid.Column>
                                        ) )
                                    }
                                    </Grid>
                                </Responsive>
                                {
                                    this.state.pages > 1 &&
                                    <Pagination
                                        className="marginTopMedium"
                                        boundaryRange = { 0 }
                                        defaultActivePage = { this.state.page }
                                        ellipsisItem = { null }
                                        firstItem = { null }
                                        lastItem =  { null }
                                        siblingRange = { this.getPaginationRange() }
                                        totalPages = { this.state.pages }
                                        onPageChange = { ( _, details ) => this.onPageChange( details.activePage ) }
                                    />
                                }
                            </React.Fragment>
                        }
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <ResponsiveLeaderboardAd />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </React.Fragment>
        }  
    }

export default Moves;