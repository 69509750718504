import axios from 'axios';
import { listErrorReasons } from '../common/utils';

const ENDPOINT_REGISTRATION = '/api/accounts/register';
const ENDPOINT_LOGIN = '/api/auth/login';
const ENDPOINT_GET_USER = '/api/accounts/getUser';
const ENDPOINT_CONFIRM_EMAIL = '/api/accounts/confirmEmail';
const ENDPOINT_FORGOT_PASSWORD = '/api/accounts/forgotPassword';
const ENDPOINT_RESET_PASSWORD = '/api/accounts/resetPassword';

class UserService
{
    register(email, password, firstName, lastName, subscribe)
    {
       let data = JSON.stringify({ email, password, firstName, lastName, subscribe });

       return new Promise( (resolve, reject) =>
       {
            axios.post(ENDPOINT_REGISTRATION, data).then( () =>
            {
                resolve();
            } ).catch( ( error ) =>
            {           
                const reasons = listErrorReasons( error );
                reject( reasons )
            });
       });
    }

    confirmEmail(userId, token)
    {
        const query = {
            params: {
                userId,
                token
            }
        }

       return new Promise( (resolve, reject) =>
       {
            axios.get(ENDPOINT_CONFIRM_EMAIL, query).then( () =>
            {
                resolve();
            } ).catch( ( error ) =>
            {           
                const reasons = listErrorReasons( error );
                reject( reasons )
            });
       });
    }

    forgotPassword(email)
    {
        const query = {
            params: {
                email
            }
        }

       return new Promise( (resolve, reject) =>
       {
            axios.get(ENDPOINT_FORGOT_PASSWORD, query).then( () =>
            {
                resolve();
            } ).catch( ( error ) =>
            {           
                const reasons = listErrorReasons( error );
                reject( reasons )
            });
       });
    }

    resetPassword(userId, token, newPassword)
    {
       let data = JSON.stringify({ userId, token, newPassword });

       return new Promise( (resolve, reject) =>
       {
            axios.post(ENDPOINT_RESET_PASSWORD, data).then( () =>
            {
                resolve();
            } ).catch( ( error ) =>
            {           
                const reasons = listErrorReasons( error );
                reject( reasons )
            });
       });
    }

    login( userName, password ) {
        let data = JSON.stringify({ userName, password });
 
        return new Promise( (resolve, reject) =>
        {
            axios.post(ENDPOINT_LOGIN, data).then( (res) =>
            {
                localStorage.setItem('auth_token', res.data.auth_token);
                //navigate
                resolve();
            } ).catch( ( error ) =>
            {
                const reasons = listErrorReasons( error );
                reject( reasons );
            });
        });
    }

    logout(){
        localStorage.removeItem('auth_token');
    }

    isLoggedIn(){
        return (localStorage.getItem("auth_token") !== null);
    }

    GetUser() {
        return new Promise( (resolve, reject) =>
        {
            axios.get(ENDPOINT_GET_USER).then( (res) =>
            {
                resolve( res.data );
            } ).catch( ( error ) =>
            {           
                const reasons = listErrorReasons( error );
                reject( reasons );
            });
        });
    }
}

export default new UserService();