import React from 'react';
import { Menu, Icon, Dropdown } from 'semantic-ui-react';
import { Link } from "react-router-dom";
import ResponsiveNavBar from '../../components/responsiveNavBar';
import UserControl from '../users/userControl';

class NavBar extends React.Component
{

  items = [
    { 
      as: Link ,
      to: '/',
      key: "home",
      content: "Home"
    },
    { 
      as: Link ,
      to: '/moves',
      key: "moves",
      content: <React.Fragment>
          <Icon name="search" style={{ display: "inline-block", verticalAlign: "middle"}}/>
          Browse Moves
      </React.Fragment>
    },
    { 
      as: Link ,
      to: '/about',
      key: "about",
      content: "About"
    }
  ];

  mainMenuItems = [ 
    ...this.items,
    { 
      as: Dropdown,
      key: "contribute",
      item : true,
      text:'Contribute',
      className:"simulatedLink",
      children: <Dropdown.Menu className="mymenu">
        <Dropdown.Item>
            <Menu.Item as={ Link } to="/contribute" onClick={this.handleItemClick}>Contribute</Menu.Item>
        </Dropdown.Item>
        <Dropdown.Item>
            <Menu.Item as={ Link } to="/support" onClick={this.handleItemClick}>Support the project</Menu.Item>
        </Dropdown.Item>
      </Dropdown.Menu>

    },
    { 
      as: Dropdown,
      key: "contact",
      item : true,
      text:'Contact',
      className:"simulatedLink",
      children: <Dropdown.Menu className="mymenu">
        <Dropdown.Item>
            <Menu.Item as={ Link } to="/contact" onClick={this.handleItemClick}>Contact us</Menu.Item>
        </Dropdown.Item>
        <Dropdown.Item>
            <Menu.Item as={ Link } to="/move/suggest" onClick={this.handleItemClick}>Suggest a Move</Menu.Item>
        </Dropdown.Item>
      </Dropdown.Menu>

    },
    { 
      as: Dropdown,
      key: "cheBlog",
      item : true,
      text:'Editorials',
      className:"simulatedLink",
      children: <Dropdown.Menu className="mymenu">
        <Dropdown.Item>
            <Menu.Item >
              <a href="http://salsalust.com/blogs/cuban_music_che/" target="_blank" rel="noopener noreferrer">
              Che's Music Blog
              </a>
            </Menu.Item>
            <Menu.Item >
              <a href="https://la-candela-salsa.de/articles/" target="_blank" rel="noopener noreferrer">
              La Candela's Articles
              </a>
            </Menu.Item>
        </Dropdown.Item>
      </Dropdown.Menu>
    }
  ];

  sideMenuItems = [ 
    ...this.items,
    { 
      as: Link ,
      to: '/contribute',
      key: "contribute",
      content: "Contribute"
    },
    { 
      as: Link ,
      to: '/support',
      key: "support",
      content: "Support the project"
    },
    { 
      as: Link ,
      to: '/contact',
      key: "about",
      content: "Contact us"
    },
    { 
      as: Link ,
      to: '/move/suggest',
      key: "suggest",
      content: "Suggest a Move"
    },
    {
      as: React.Fragment,
      key: "cheBlog",
      children: <a href="http://salsayo.com/blogs/cuban_music_che/" target="_blank" rel="noopener noreferrer">
        Che's Music Blog
      </a>
    }];

  rightItems = [
    {
      content : <UserControl/>,
      key: 'userControl'
    }];

  render()
  {
    return <ResponsiveNavBar leftItems = { this.mainMenuItems } 
                             sidebarItems = { this.sideMenuItems }
                             rightItems = { this.rightItems }>
      {this.props.children}
    </ResponsiveNavBar>;
  }
}

export default NavBar;