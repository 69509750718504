import React, { Component } from 'react';
import { Link } from "react-router-dom";
import WebsiteMessagePage from '../parts/websiteMessagePage';
class RegistrationComplete extends Component
{

  render()
  {
    return <WebsiteMessagePage icon = "mail"
                               title = "Registration successful - Confirm your email"
                               content = { <React.Fragment>
                                  Your registration was successful. In order to complete the registration please check your email inbox and confirm your email.
                                  <br/><br/>
                                  Go back to <Link className="colorStrongBlue" to="/">home page</Link>
                               </React.Fragment>}
            />;
    }
}

export default RegistrationComplete;