import React, { Component } from 'react';
import { Grid, Form, Input, Button, Message, Checkbox } from 'semantic-ui-react';
import StandardHelmet from '../parts/standardHelmet';
import UserService from '../../services/userService';
import { validateEmail } from '../../common/utils';
import { FORM_STATUS } from '../../common/enums';
import RegistrationComplete from './registrationComplete';

class Registration extends Component
{
    state = {
        email   : '',
        password : '',
        passwordRepeat : '',
        firstName : '',
        lastName : '',
        subscribe : true,
        status  : FORM_STATUS.INITIAL,
        errorMessages : [],
        errorFields : []
    }
    
    handleChange = (_, { name, value }) => this.setState({ [name] : value })
    handleCheck = (_, { name, checked }) => this.setState({ [name] : checked })

    validate = () =>
    {
        const { firstName, lastName, email, password, passwordRepeat } = this.state;

        let valid = true;
        const errorFields = [];
        const messages = [];

        const listError = ( field, message ) => {
            valid = false;
            errorFields.push( field );
            messages.push( message );
        }

        if ( !firstName ) listError( 'firstName', 'First name is required.' );
        if ( !lastName ) listError( 'lastName', 'Last name is required.' );
        if ( !validateEmail( email) ) listError( 'email', 'Invalid email.' );
        if ( password.length < 8 ) listError( 'password', 'Password should be at least 8 characters' );
        if ( password !== passwordRepeat ) listError( 'passwordRepeat', 'Password and the re-password do not match.' );

        this.setState({
            status : FORM_STATUS.VALIDATED,
            errorFields,
            errorMessages : messages
        });

        return valid;
    }

    handleSubmit = () =>
    {
        if ( !this.validate() ) return;

        this.setState( { status : FORM_STATUS.SENDING } );
        UserService.register( this.state.email, 
                              this.state.password,
                              this.state.firstName,
                              this.state.lastName,
                              this.state.subscribe )
                    .then( ( a ) => 
                    {
                        this.setState( { 
                            status : FORM_STATUS.SUCCESS,
                        } );
                    })
                    .catch( ( errors ) =>
                    {
                        this.setState( { 
                            status       : FORM_STATUS.ERROR,
                            errorMessages : errors,
                            errorFields  : [] // From server no errors on specific fields
                        } );
                    });
    }

    render()
    {
    return <React.Fragment>
        <StandardHelmet title = 'Register | Salsa Yo' />
        {
            (this.state.status === FORM_STATUS.SUCCESS) ? <RegistrationComplete/> :
            <Grid centered={true}>
                <Grid.Column width = {16}>
                <Form onSubmit = { this.handleSubmit }
                    loading = { this.state.status === FORM_STATUS.SENDING }
                    error = { this.state.status === FORM_STATUS.ERROR }
                    warning = { this.state.status === FORM_STATUS.VALIDATED }
                    success = { this.state.status === FORM_STATUS.SUCCESS }>
                    <Form.Field
                        name        = 'firstName'
                        value       = { this.state.firstName }
                        control     = { Input }
                        label       = 'Name'
                        placeholder = 'first name'
                        onChange    = {this.handleChange}
                        error       = { this.state.status === FORM_STATUS.VALIDATED && this.state.errorFields.includes('firstName')}
                    />
                    <Form.Field
                        name        = 'lastName'
                        value       = { this.state.lastName }
                        control     = { Input }
                        label       = 'Surname'
                        placeholder = 'last name'
                        onChange    = {this.handleChange}
                        error       = { this.state.status === FORM_STATUS.VALIDATED && this.state.errorFields.includes('lastName')}
                    />
                    <Form.Field
                        name        = 'email'
                        value       = { this.state.email }
                        control     = { Input }
                        label       = 'Email'
                        placeholder = 'email'
                        onChange    = {this.handleChange}
                        error       = { this.state.status === FORM_STATUS.VALIDATED && this.state.errorFields.includes('email')}
                    />
                    <Form.Field
                        type        = 'password'
                        name        = 'password'
                        value       = { this.state.password }
                        control     = { Input }
                        label       = 'Password'
                        placeholder = 'Password'
                        onChange    = {this.handleChange}
                        error       = { this.state.status === FORM_STATUS.VALIDATED && this.state.errorFields.includes('password')}
                    />
                    <Form.Field
                        type        = 'password'
                        name        = 'passwordRepeat'
                        value       = { this.state.passwordRepeat }
                        control     = { Input }
                        label       = 'Repeat Password'
                        placeholder = 'Password'
                        onChange    = {this.handleChange}
                        error       = { this.state.status === FORM_STATUS.VALIDATED && this.state.errorFields.includes('passwordRepeat')}
                    />
                    <Form.Field
                        type        = "checkbox"
                        name        = 'subscribe'
                        checked     = { this.state.subscribe }
                        control     = { Checkbox }
                        label       = "Subscribe to our Newsletter. It's mostly just about major news."
                        placeholder = 'Password'
                        onChange    = {this.handleCheck}
                    />
                    <Message success header='Registration Successful' />
                    <Message warning header='Fields are missing or incorrect' list={ this.state.errorMessages } />
                    <Message error header='Registration Failed' list={ this.state.errorMessages } />
                    <Button type='submit'>Submit</Button>
                </Form>
                </Grid.Column>
            </Grid> 
        }
    </React.Fragment>;
    }
}

export default Registration;