import React, { Component } from 'react';
import _ from 'lodash';
import { Header, Grid, Tab, Table, Loader, Popup, Icon } from 'semantic-ui-react';
import { Link } from "react-router-dom";
import YouTube from 'react-youtube';
import axios from 'axios';
import JsxParser from 'react-jsx-parser';
import { dashDelimited, escapeChukos } from '../common/utils';
import { displayLevel, displayBarCount, displayDanceConfiguration } from '../common/displayUtils';
import ResponsiveLeaderboardAd from '../components/responsiveLeaderboardAd';
import StandardHelmet from './parts/standardHelmet';
import UserService from '../services/userService';

const apiEndpoint = '/api/moves/:name';
const apiEndpointTagMove = '/api/moves/tagmove';
const apiEndpointUntagMove = '/api/moves/untagmove';

const MetadataTooltips = {
    level : 'Indicates the complexity for a move.',
    translation : 'Most moves names are in Spanish. This is the English meaning of the move.',
    danceConfiguration : 'Rueda moves are move that are predominately used for Rueda de Casino rather than in partner dance.',
    ruedaSign : 'In addition to their name, many moves have a known visual sign that the rueda leader can use in order to call out moves.',
    synonyms : 'Alternative names that are used for this move.',
    startsWith : 'Many moves are starting with another known move.',
    basedOn : 'Some moves are for the most part an extension of another move.',
    counts : 'The length of the move counting bars (bar = 1,2,3 - 5,6,7)',
    SimilarTo : 'Similiar moves to this one.'
}

const hoverPopupProps = {
    on : 'hover',
    mouseEnterDelay : 500
}
class Move extends Component {

    state = {
        move: null,
        favoriteMarkInProgress : false
    }

    componentDidMount()
    {
        this.fetchMove();
    };

    componentWillReceiveProps(nextProps)
    {
        if (nextProps.match.params.name !== this.props.match.params.name)
        {
            this.fetchMove( nextProps );
        }
    }

    fetchMove( props = this.props)
    {
        const { name } = props.match.params;
        const endpoint = apiEndpoint.replace(':name', name);

        axios.get(endpoint).then( (res) =>
        {
            let move = res.data;
            move.description = this.modifyMoveReferencesToLinks( move.description);

            this.setState({ move });
        } );
    }

    modifyMoveReferencesToLinks( jsx )
    {
        return jsx.replace( /\$(.+?)(@(.+?))?\$/g, ( _, moveName, unused, moveReference ) =>
        {
            const reference = moveReference || moveName;
            let safeReference = dashDelimited(reference);
            safeReference = escapeChukos(safeReference);
            return `<Link to={'/move/${safeReference}'}>${ moveName }</Link>`;
        } );
    }

    covertMovesNamesToLinks( text )
    {
        const movesNames = text.split(',');
        let movesReference = movesNames.map(moveName => {
            return <><Link to={`/move/${moveName}`}>{ moveName }</Link>, </>
        });
        return movesReference;
    }

    getTooltip( metaDataKey )
    {
        return <React.Fragment>
            { MetadataTooltips[ metaDataKey ] }
            <br/>
            <Link to="/move-explanation">More information...</Link>
        </React.Fragment>
    }

    getTitle()
    {
        return ( this.state.move ) ? `${this.state.move.name} | Salsa Yo` :
                                     ' loading move... | Salsa Yo';
    }

    toggleFavorite()
    {
        if ( this.state.favoriteMarkInProgress )
        {
            return;
        }

        const removeFavoriteTag = () =>
        {
            const move = { ...this.state.move };
            _.remove( move.tags, ( tag ) => tag.category === 1 );
            this.setState({ move });
        }

        const addTag = ( tag ) =>
        {
            const move = { ...this.state.move };
            move.tags.push( tag );
            this.setState({ move });
        }

        const favoriteTag = {
            moveId : this.state.move.id,
            category: 1
        };

        this.setState({
            favoriteMarkInProgress : true
        });

        if ( this.isFavorite() )
        {
            removeFavoriteTag();
            axios.delete(apiEndpointUntagMove, { data: favoriteTag }).catch( () =>
            {
                addTag( favoriteTag );
            })
            .finally( () => {
                this.setState({
                    favoriteMarkInProgress : false
                });
            });
        }
        else 
        {
            addTag( favoriteTag );
            axios.post(apiEndpointTagMove, favoriteTag).catch( () =>
            {
                removeFavoriteTag();
            })
            .finally( () => {
                this.setState({
                    favoriteMarkInProgress : false
                });
            });
        }
    }

    isFavorite()
    {
        const favoriteTag = this.state.move.tags.find( t => t.category === 1);
        return ( !!favoriteTag );
    }

    getFavoriteIcon()
    {
        if (UserService.isLoggedIn())
        {
            const favoriteIconColor = ( this.isFavorite() ) ? 'yellow' : 'black';

            return <Popup
                trigger={<Icon name='star' color = { favoriteIconColor } className="floatRight" onClick={ () => this.toggleFavorite()} />}
                content="My Favorite"
                {...hoverPopupProps}
            />;
        }
        else
        {
            return <Popup
                trigger={<Icon name='star' color = 'black' className="floatRight" />}
                content={<p>Marking moves as your favorite requires to login or <Link to="/register">registration</Link>.</p>}
                on='click'
            />
        }
    }

    render()
    {
        if ( !this.state.move ) return <Loader size='massive' active={true} className="marginTopMassive">Loading move...</Loader>;
        
        const videoPanes = this.state.move.videos.map( ( video, index ) => 
        {
            const pane = {};

            if ( this.state.move.videos.length > 1 )
            {
                pane.menuItem = {
                    key     : video.id,
                    content : <React.Fragment>
                        { (video.instructional === true) ? <Icon name = "graduation cap" /> : null }
                        { video.production.name }
                    </React.Fragment>
                };

                if ( index === 0 )
                {
                    pane.menuItem.icon = 'star';
                }
            }

            pane.render = () => <Tab.Pane>
                <div className="videoRatioBox">
                    <YouTube videoId={ video.video } />
                </div>
                <p className = "textCenter marginTop"> Thanks to: <a href= { video.production.link } target="_blank" rel="noopener noreferrer">{ video.production.name }</a></p>
            </Tab.Pane>;

            return pane;
        } );

        return <React.Fragment>
            <StandardHelmet title = { this.getTitle() }/>
            <Grid stackable>
                <Grid.Row centered>
                    <Grid.Column width={7} textAlign="center">
                        <div className="metadataContainer">
                            <Link to="/move-explanation">
                                <Icon name="info circle" className="floatRight"></Icon>
                            </Link>
                            {this.getFavoriteIcon()}
                            <h1 className = "moveTitle">{this.state.move.name}</h1>
                            <Table basic="very" collapsing unstackable className="metadataTable">
                                <Table.Body>
                                    <Table.Row verticalAlign="top">
                                        <Table.Cell>
                                            <Popup content={ this.getTooltip( 'level' ) }
                                                {...hoverPopupProps}
                                                trigger = {<p className="bold">Level</p>} />
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Link to={`/moves?level=${this.state.move.level}`}>
                                                <p>{displayLevel(this.state.move.level)}</p>
                                            </Link>
                                        </Table.Cell>
                                    </Table.Row>
                                    {
                                    this.state.move.synonyms &&
                                    <Table.Row verticalAlign="top">
                                        <Table.Cell>
                                            <Popup content={ this.getTooltip( 'synonyms' ) }
                                                {...hoverPopupProps}
                                                trigger = {<p className="bold">Synonyms</p>} />
                                        </Table.Cell>
                                        <Table.Cell><p>{this.state.move.synonyms}</p></Table.Cell>
                                    </Table.Row>
                                    }
                                    <Table.Row verticalAlign="top">
                                        <Table.Cell>
                                            <Popup content={ this.getTooltip( 'translation' ) }
                                                {...hoverPopupProps}
                                                trigger = {<p className="bold">Translation</p>} />
                                        </Table.Cell>
                                        <Table.Cell><p>{this.state.move.translation}</p></Table.Cell>
                                    </Table.Row>
                                    <Table.Row verticalAlign="top">
                                        <Table.Cell>
                                            <Popup content={ this.getTooltip( 'danceConfiguration' ) }
                                                {...hoverPopupProps}
                                                trigger = {<p className="bold">Move Type</p>} />
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Link to={`/moves?danceConfiguration=${+this.state.move.danceConfiguration}`}>
                                                <p>{displayDanceConfiguration(this.state.move.danceConfiguration)}</p>
                                            </Link>
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row verticalAlign="top">
                                        <Table.Cell>
                                            <Popup content={ this.getTooltip( 'ruedaSign' ) }
                                                {...hoverPopupProps}
                                                trigger = {<p className="bold">Rueda Sign</p>} />
                                        </Table.Cell>
                                        <Table.Cell><p>{this.state.move.sign}</p></Table.Cell>
                                    </Table.Row>
                                    {
                                    this.state.move.startsWith && 
                                    <Table.Row verticalAlign="top">
                                        <Table.Cell>
                                            <Popup content={ this.getTooltip( 'startsWith' ) }
                                                {...hoverPopupProps}
                                                trigger = {<p className="bold">Starts with</p>} />
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Link to={`/move/${dashDelimited(this.state.move.startsWith)}`}>
                                                <p>{this.state.move.startsWith}</p>
                                            </Link>
                                        </Table.Cell>
                                    </Table.Row>
                                    }
                                    {
                                    this.state.move.basedOn &&
                                    <Table.Row verticalAlign="top">
                                        <Table.Cell>
                                            <Popup content={ this.getTooltip( 'basedOn' ) }
                                                {...hoverPopupProps}
                                                trigger = {<p className="bold">Based on</p>} />
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Link to={`/move/${dashDelimited(this.state.move.basedOn)}`}>
                                                <p>{this.state.move.basedOn}</p>
                                            </Link>
                                        </Table.Cell>
                                    </Table.Row>  
                                    }
                                    {
                                    this.state.move.similarTo &&
                                    <Table.Row verticalAlign="top">
                                        <Table.Cell>
                                            <Popup content={ this.getTooltip( 'similarTo' ) }
                                                {...hoverPopupProps}
                                                trigger = {<p className="bold">Similar To</p>} />
                                        </Table.Cell>
                                        <Table.Cell>
                                            <p>{this.covertMovesNamesToLinks(this.state.move.similarTo)}</p>
                                        </Table.Cell>
                                    </Table.Row>  
                                    }
                                    <Table.Row verticalAlign="top">
                                        <Table.Cell>
                                            <Popup content={ this.getTooltip( 'counts' ) }
                                                {...hoverPopupProps}
                                                trigger = {<p className="bold">Bar Counts</p>} />
                                        </Table.Cell>
                                        <Table.Cell><p>{displayBarCount(this.state.move.counts)}</p></Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </div>
                        <div className="textRight">
                            <Link to={`/contact?subject=Regarding ${this.state.move.name}`}>Anything to add or correct?</Link>
                            
                        </div>
                    </Grid.Column>
                    <Grid.Column width={9}>
                        <Tab panes = { videoPanes }
                             menu = { { attached: 'bottom', secondary: true, className : "videoTabs" } } />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <ResponsiveLeaderboardAd />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered>
                    <Grid.Column width={16}>
                        <Header as="h2">Step by step description</Header>
                        {
                            this.state.move.description ?
                            <p className="textJustify">
                                <JsxParser jsx={this.state.move.description}
                                        components = { {Link} } />
                            </p>
                            :
                            <Link to={`/contact?subject=Description for: ${this.state.move.name}`}>Add your description</Link>
                        }
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </React.Fragment>
    }
}

export default Move;