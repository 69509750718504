import React from 'react';
import { Router, Route, Switch, Link } from "react-router-dom";
import { Grid, Image, Responsive } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';
import { createBrowserHistory } from 'history'
import ReactGA  from 'react-ga';
import axios from 'axios';
import './App.css';
import 'semantic-ui-css/semantic.min.css'
import NavBar from './pages/parts/navBar';
import SocialBar from './pages/parts/socialBar';
import ScrollToTop from './components/scrollToTop';
import ResponsiveLeaderboardAd from './components/responsiveLeaderboardAd';
import Index from './pages';
import Move from './pages/move';
import Moves from './pages/moves';
import About from './pages/about';
import Contribute from './pages/contribute';
import Contact from './pages/contact';
import MoveExplanation from './pages/moveExplanation';
import SuggestMove from './pages/suggestMove';
import Support from './pages/support';
import NotFound from './pages/404';
import Logo from './images/logo.png';
import Registration from './pages/users/registration';
import ConfirmEmail from './pages/users/confirmEmail';
import ResetPassword from './pages/users/resetPassword';
import UserContext from './contexts/UserContext';
import UserService from './services/userService';

const TITLE = 'Salsa Yo - Source for all Salsa moves';
const DESCRIPTION = 'The Source for all of salsa dance moves. Wanted to know how to salsa? Need a remainder of your salsa dance class? this is an encyclopedia of all moves including videos and descriptions for quick learning.';

ReactGA.initialize('UA-2185229-9');

const history = createBrowserHistory();
history.listen(location => {
  const fullLocation = location.pathname + location.search;
	ReactGA.set({ page: fullLocation })
	ReactGA.pageview(fullLocation)
});

axios.defaults.headers.post['Content-Type'] = 'application/json';
const authToken = localStorage.getItem('auth_token');
if (authToken !== null)
{
  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
}

class App extends React.Component
{
  state = {
    user: null
  }

  componentDidMount()
  {
    axios.interceptors.response.use(null , ( error ) => {
      if ( error.response && error.response.status === 401)
      {
        this.setState({
          user : null
        });
        UserService.logout();
      }
      return Promise.reject(error);
    });

    ReactGA.pageview(window.location.pathname + window.location.search);
    this.setUser();
  }

  componentDidUpdate(prevProps) {
    this.setUser();
  }

  setUser()
  {
    if ( !this.state.user && UserService.isLoggedIn() )
    {
      UserService.GetUser().then(( user ) => {
        this.setState({
          user
        });
      });
    }
  }

  render()
  {
    const userContext = { 
      user : this.state.user
    }

    return (
      <UserContext.Provider value={userContext}>
        <Router history={history}>
          <ScrollToTop>
            <div className="center">
              <Helmet>
                <title>{ TITLE }</title>
                <meta name="description" content={ DESCRIPTION } />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={ TITLE } />
                <meta property="og:description" content={ DESCRIPTION } />
                {/* <meta property="og:image" content={ Logo } /> */}
                <meta property="og:site_name" content="Salsa Yo" />
                <meta name="twitter:title" content= { TITLE }/>
                <meta name="twitter:description" content= { DESCRIPTION }/>
                <meta name="twitter:image" content={ Logo } />
              </Helmet>
              <NavBar>
                <Grid>
                  <Grid.Row centered>
                    <Link to="/">
                      <Image src = { Logo } alt="Salsa Yo Logo" size="medium"/>
                    </Link>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width={16} style={{display: 'flex'}}>
                      <Responsive minWidth={Responsive.onlyTablet.minWidth} className="marginLeftAuto">
                        <SocialBar/>
                      </Responsive>
                      <Responsive {...Responsive.onlyMobile} className="marginCenter">
                        <SocialBar/>
                      </Responsive>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width={16}>
                      <ResponsiveLeaderboardAd />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column>
                      <Switch>
                        <Route path="/" exact component={Index} />
                        <Route path="/move/suggest" component={SuggestMove} />
                        <Route path="/move/:name" component={Move} />
                        <Route path="/moves/" component={Moves} />
                        <Route path="/about" component={About} />
                        <Route path="/contribute" component={Contribute} />
                        <Route path="/contact" component={Contact} />
                        <Route path="/move-explanation" component={MoveExplanation} />
                        <Route path="/support" component={Support} />
                        <Route path="/register" component={Registration} />
                        <Route path="/confirm-email" component={ConfirmEmail} />
                        <Route path="/reset-password" component={ResetPassword} />
                        <Route component={NotFound} />
                      </Switch>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    Copyright © 2010-{new Date().getFullYear()} Ido Flaishon. All Rights Reserved.
                  </Grid.Row>
                </Grid>
              </NavBar>
            </div>
          </ScrollToTop>
        </Router>
      </UserContext.Provider>
    );
  }
}

export default App;
